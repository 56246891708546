import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  UploadFile,
} from '@pankod/refine-antd'
import { SingleDraggerUploader } from 'components/uploaders'
import dayjs from 'dayjs'

interface IModal {
  modalProps: ModalProps
  formProps: FormProps
  onFinish: (values?: any) => Promise<void>
}

interface IFormValue {
  image: UploadFile[] | undefined
  link: string | undefined
  range: [dayjs.Dayjs, dayjs.Dayjs] | undefined
}

export const BannerModal: React.FC<IModal> = ({
  modalProps,
  formProps,
  onFinish,
}) => {
  const handleSubmit = (values: IFormValue) => {
    const { image, link, range } = values
    const [start_time, end_time] = (range || []).map((e: dayjs.Dayjs) =>
      formProps.initialValues ? e.unix() : e.utcOffset(0, true).unix(),
    )
    onFinish({
      image,
      link,
      start_time,
      end_time,
    })
  }

  return (
    <Modal {...modalProps} width={600}>
      <Form
        {...formProps}
        onFinish={handleSubmit}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          image: formProps.initialValues?.image,
          link: formProps.initialValues?.link,
          range: formProps.initialValues?.start_time
            ? [
              dayjs.unix(formProps.initialValues.start_time).utc(),
              dayjs.unix(formProps.initialValues.end_time).utc(),
            ]
            : undefined,
        }}
      >
        <Form.Item label="Banner" name="image" rules={[{ required: true }]}>
          <SingleDraggerUploader />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[
            { required: true },
            {
              message: 'link should start with http(s)',
              pattern: /^https?:\/\//i,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Range"
          name="range"
          tooltip="UTC time"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker showTime className="w-full" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

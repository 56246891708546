import {
  Button,
  DateField,
  DeleteButton,
  Form,
  Icons,
  Input,
  List,
  Pagination,
  Popconfirm,
  ShowButton,
  Space,
  Table,
  TextField,
  useForm,
} from '@pankod/refine-antd'
import {
  CrudFilter,
  IResourceComponentsProps,
  useCreate,
  useTable,
} from '@pankod/refine-core'
import classNames from 'classnames'
import { APP_PC_URL } from 'commons/constants'
import { IUser } from 'commons/interfaces'
import dayjs from 'dayjs'
import { useState } from 'react'
import styles from './style.module.css'

// const initialFilter: CrudFilter = {
//   field: 'denied',
//   value: true,
//   operator: 'eq',
// }

export const Certusers: React.FC<IResourceComponentsProps> = () => {
  const apiPath = 'users_approve'
  const [isSearchMode, setIsSearchMode] = useState(false)

  const { form: searchForm } = useForm()

  const { tableQueryResult, current, setCurrent, setFilters } =
    useTable<IUser>({
      resource: isSearchMode ? 'users:search' : apiPath,
      // initialFilter: [initialFilter],
      metaData: {
        resourceName: 'users'
      },
    })

  const handleReset = () => {
    searchForm.resetFields()
    handleSearch()
  }

  const handleSearch = () => {
    const values = searchForm.getFieldsValue()
    const filters = Object.entries(values).map(
      (entry) =>
      ({
        field: entry[0],
        value: entry[1],
        operator: 'eq',
      } as CrudFilter),
    )
    setIsSearchMode(filters.filter(e => !e.value).length < 1)
    setCurrent(1)
    setFilters(filters)
  }

  const { mutate: addToList, isLoading: isAdding } = useCreate()

  const handleAdd = (user: IUser) => {
    addToList(
      {
        resource: apiPath,
        values: {
          uid: user.uid,
        },
        successNotification: {
          description: 'Success',
          type: 'success',
          message: `Successfully add certification for user '${user.display_name}'`
        },
      },
      {
        onSuccess: () => {
          user.approved = 1
        },
      },
    )
  }

  return (
    <List title="" pageHeaderProps={{ breadcrumb: <></> }}>
      <div className="flex-between" style={{ marginBottom: 8 }}>
        <Form
          layout="inline"
          style={{ marginBottom: 12 }}
          form={searchForm}
          onFinish={handleSearch}
        >
          <Form.Item label="Keywords" name="query">
            <Input placeholder="Search in wallet/name" onPressEnter={handleSearch} />
          </Form.Item>
        </Form>
        <Space style={{ marginBottom: 12 }}>
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </Space>
      </div>
      <Table
        dataSource={tableQueryResult.data?.data}
        loading={
          tableQueryResult.isLoading ||
          tableQueryResult.isFetching ||
          tableQueryResult.isRefetching
        }
        rowKey="uid"
        pagination={false}
      >
        <Table.Column
          dataIndex="address"
          key="address"
          title="Wallet Address"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.address)}
        />
        <Table.Column
          dataIndex="display_name"
          key="display_name"
          title="Nick Name"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.name)}
        />
        <Table.Column
          dataIndex="create_time"
          key="create_time"
          title="SignUp At (UTC)"
          render={(value) => (
            <DateField
              value={dayjs.unix(value).utc()}
              format="YYYY.MM.DD HH:mm"
            />
          )}
          className={classNames(styles.Col, styles.date)}
        />
        <Table.Column
          dataIndex="approved"
          key="approved"
          title="Status"
          render={(value) => <TextField style={{ color: value ? '#fa541c' : 'inherit' }} value={value ? 'Certified' : 'Normal'} />}
          className={classNames(styles.Col, styles.status)}
        />
        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {record.approved ? (
                <DeleteButton
                  hideText
                  resourceNameOrRouteName={apiPath}
                  size="small"
                  recordItemId={record.uid}
                  confirmOkText='Yes'
                  confirmTitle='Remove certification from this user?'
                  successNotification={{
                    description: 'Success',
                    type: 'success',
                    message: `Successfully remove certification from user '${record.display_name}'`,
                  }}
                  onSuccess={() => {
                    record.denied = false
                    handleReset()
                  }}
                />
              ) : (
                <Popconfirm
                  title='Add certification for this user?'
                  onConfirm={() => handleAdd(record)}
                  okText='Yes'
                  okType='danger'
                >
                  <Button size='small' icon={<Icons.PlusOutlined />} loading={isAdding} danger></Button>
                </Popconfirm>
              )}
              <ShowButton
                hideText
                size='small'
                recordItemId={record.uid}
                onClick={() => window.open(`${APP_PC_URL}/profile/${record.uid}`, '_blank')}
              />
            </Space>
          )}
          className={classNames(styles.Col, styles.action)}
        />
      </Table>
      <Pagination
        simple
        current={current}
        onChange={(page: number) => setCurrent(page)}
        total={tableQueryResult.data?.total || 0}
        style={{ textAlign: 'right', marginTop: 20 }}
      />
    </List>
  )
}

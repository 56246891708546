import {
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  Form,
  ImageField,
  Input,
  List,
  Space,
  Table,
  TextField,
  useForm,
  useModal,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useApiUrl,
  useCreate,
  useCustom,
} from '@pankod/refine-core';
import classNames from 'classnames';
import { IChannel } from 'commons/interfaces';
import { ChannelRecSortModal } from 'components/modals';
import dayjs from 'dayjs';
import { useState } from 'react';
import styles from './style.module.css';

export const ChannelRecList: React.FC<IResourceComponentsProps> = () => {
  const apiPath = 'channels_recommend';
  const apiUrl = useApiUrl();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState<{}>();
  const { form: searchForm } = useForm();

  const {
    data: recResp,
    isLoading,
    isRefetching,
    refetch,
  } = useCustom({
    url: `${apiUrl}/${apiPath}`,
    method: 'get',
    queryOptions: {
      enabled: !isSearchMode,
    },
  });

  const {
    data: searchResp,
    refetch: reSearch,
    isLoading: isSearching,
    isRefetching: isReSearching,
  } = useCustom({
    url: `${apiUrl}/channels:search`,
    method: 'get',
    config: {
      query: searchQuery,
    },
    queryOptions: {
      enabled: isSearchMode,
    },
  });

  const { mutate: addToRecList, isLoading: isAdding } = useCreate();

  const {
    modalProps: sortModalProps,
    show: sortModalShow,
    close: sortModalClose,
  } = useModal();

  const handleReset = () => {
    searchForm.resetFields();
    setIsSearchMode(false);
  };

  const handleSearch = () => {
    setSearchQuery(searchForm.getFieldsValue());
    setIsSearchMode(true);
  };

  const handleSortSaved = () => {
    sortModalClose();
    refetch();
  };

  const handleAdd = (channel: IChannel) => {
    addToRecList(
      {
        resource: apiPath,
        values: {
          channel_id: channel.channel_id,
        },
        successNotification: {
          description: 'Success',
          type: 'success',
          message: `Channel '${channel.name}' added to rec list successfully`,
        },
      },
      {
        onSuccess: () => (isSearchMode ? reSearch() : refetch()),
      },
    );
  };

  return (
    <List title="" pageHeaderProps={{ breadcrumb: <></> }}>
      <div className="flex-between" style={{ marginBottom: 8 }}>
        <Form
          layout="inline"
          style={{ marginBottom: 12 }}
          form={searchForm}
          onFinish={handleSearch}
        >
          <Form.Item label="Name" name="name">
            <Input
              placeholder="Input channel name"
              onPressEnter={handleSearch}
            />
          </Form.Item>
          <Form.Item label="Creator" name="creator">
            <Input
              placeholder="Input creator name"
              onPressEnter={handleSearch}
            />
          </Form.Item>
        </Form>
        <Space style={{ marginBottom: 12 }}>
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button type="primary" onClick={() => sortModalShow()}>
            Sort
          </Button>
        </Space>
      </div>
      <Table
        dataSource={
          isSearchMode ? searchResp?.data.channels : recResp?.data.channels
        }
        loading={isLoading || isRefetching || isSearching || isReSearching}
        rowKey="channel_id"
        pagination={false}
      >
        <Table.Column
          key="order"
          title="Order"
          render={(_, __, index) => <TextField value={index + 1} />}
        />
        <Table.Column<IChannel>
          dataIndex="cover"
          key="cover"
          title="Cover"
          render={(value, record) =>
            value ? (
              <ImageField
                value={value}
                title={record.name}
                width={80}
                height={80}
              />
            ) : (
              <div style={{ height: 80 }}></div>
            )
          }
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.name)}
        />
        <Table.Column
          dataIndex="creator"
          key="creator"
          title="Creator"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.creator)}
        />
        <Table.Column
          dataIndex="update_time"
          key="update_time"
          title="Updated At (UTC)"
          render={(value) => (
            <DateField
              value={dayjs.unix(value).utc()}
              format="YYYY.MM.DD HH:mm"
            />
          )}
        />
        <Table.Column<IChannel>
          title="Action"
          dataIndex="action"
          width={90}
          className="text-center"
          align="center"
          render={(_, record) =>
            record.weight > 0 ? (
              <DeleteButton
                hideText
                size="small"
                confirmOkText="Remove"
                resourceNameOrRouteName={apiPath}
                recordItemId={record.channel_id}
                confirmTitle="Remove from list?"
                successNotification={{
                  description: 'Success',
                  type: 'success',
                  message: `Successfully removed channel '${record.name}' from rec list`,
                }}
                onSuccess={() => (isSearchMode ? reSearch() : refetch())}
              />
            ) : (
              <CreateButton
                hideText
                size="small"
                loading={isAdding}
                onClick={() => handleAdd(record)}
              />
            )
          }
        />
      </Table>
      <ChannelRecSortModal
        onSaved={handleSortSaved}
        modalProps={sortModalProps}
      />
    </List>
  );
};

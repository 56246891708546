import { Modal, ModalProps, Spin } from '@pankod/refine-antd';
import { BaseRecord, useApiUrl, useCustom, useList } from '@pankod/refine-core';
import { IBanner } from 'commons/interfaces';
import { Sortable } from 'components/sortables';
import { useEffect, useState } from 'react';

interface IModal {
  modalProps: ModalProps;
  onSaved: () => void;
}

export const BannerSortModal: React.FC<IModal> = ({ modalProps, onSaved }) => {
  const apiPath = 'banners';
  const apiUrl = useApiUrl();
  const [startSave, setStartSave] = useState(false);
  const [sortedIds, setSortedIds] = useState<any[]>();
  const { data, refetch, isRefetching } = useList({
    resource: apiPath,
  });
  const {
    data: sortResp,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useCustom({
    url: `${apiUrl}/${apiPath}/sort`,
    method: 'post',
    config: {
      payload: {
        ids: sortedIds,
      },
    },
    queryOptions: {
      enabled: startSave,
    },
  });

  const renderItemDetail = (value: string) => {
    const item = ((data?.data || []) as IBanner[]).find((e) => e.id === value);

    return item ? (
      <div
        style={{
          width: '100%',
          height: 140,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundImage: `url(${item.image})`,
        }}
      />
    ) : (
      value
    );
  };

  useEffect(() => {
    if (modalProps.visible) {
      refetch();
    }
  }, [modalProps.visible]);

  useEffect(() => {
    if (isSaved && sortResp.data) {
      onSaved();
    }
  }, [isSaved, sortResp]);

  return (
    <Modal
      {...modalProps}
      title="Resort Banner List"
      width={400}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'scroll' }}
      centered
      destroyOnClose
      onOk={() => setStartSave(true)}
      confirmLoading={isRefetching || isSaving}
      afterClose={() => setStartSave(false)}
    >
      {isRefetching ? (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Sortable
          items={(data?.data || []).map((e: BaseRecord) => e.id || '')}
          renderItemDetail={renderItemDetail}
          useDragOverlay={false}
          style={{ padding: 0 }}
          onChange={(ids) => setSortedIds(ids)}
        />
      )}
    </Modal>
  );
};

import { ImageField, Modal, ModalProps, Spin } from '@pankod/refine-antd';
import { useApiUrl, useCustom } from '@pankod/refine-core';
import { IChannel } from 'commons/interfaces';
import { Sortable } from 'components/sortables';
import { useEffect, useState } from 'react';

interface IModal {
  modalProps: ModalProps;
  onSaved: () => void;
}

export const ChannelRecSortModal: React.FC<IModal> = ({
  modalProps,
  onSaved,
}) => {
  const apiPath = 'channels_recommend';
  const apiUrl = useApiUrl();
  const [startSave, setStartSave] = useState(false);
  const [sortedIds, setSortedIds] = useState<any[]>();
  const { data, refetch, isRefetching } = useCustom({
    url: `${apiUrl}/${apiPath}`,
    method: 'get',
  });
  const {
    data: sortResp,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useCustom({
    url: `${apiUrl}/${apiPath}/sort`,
    method: 'post',
    config: {
      payload: {
        channel_ids: sortedIds,
      },
    },
    queryOptions: {
      enabled: startSave,
    },
  });

  const renderItemDetail = (value: string) => {
    const channel = ((data?.data.channels || []) as IChannel[]).find(
      (e) => e.channel_id === value,
    );

    return channel ? (
      <div className="flex-between w-full">
        <ImageField value={channel.cover} width={80} height={80} />
        <div style={{ textAlign: 'right' }}>
          <div style={{ maxWidth: 200 }} className="truncate">
            {channel.name}
          </div>
          <div style={{ maxWidth: 200 }} className="truncate">
            {channel.creator}
          </div>
        </div>
      </div>
    ) : (
      value
    );
  };

  useEffect(() => {
    if (modalProps.visible) {
      refetch();
    }
  }, [modalProps.visible]);

  useEffect(() => {
    if (isSaved && sortResp.data) {
      onSaved();
    }
  }, [isSaved, sortResp]);

  return (
    <Modal
      {...modalProps}
      title="Resort Channel Rec List"
      width={400}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'scroll' }}
      centered
      destroyOnClose
      onOk={() => setStartSave(true)}
      confirmLoading={isRefetching || isSaving}
      afterClose={() => setStartSave(false)}
    >
      {isRefetching ? (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Sortable
          items={(data?.data.channels || []).map((e: IChannel) => e.channel_id)}
          renderItemDetail={renderItemDetail}
          useDragOverlay={false}
          style={{ padding: 0 }}
          onChange={(ids) => setSortedIds(ids)}
        />
      )}
    </Modal>
  );
};

import { AntdLayout, Card, Grid, Icons, Menu } from '@pankod/refine-antd';
import {
  CanAccess,
  ITreeMenu,
  useMenu,
  useRefineContext,
  useRouterContext,
} from '@pankod/refine-core';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './style.module.css';
import { CustomTitle } from './title';

export const CustomSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const { SubMenu } = Menu;

  const { menuItems, selectedKey } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;
  const menuTree = hasDashboard
    ? [
        {
          icon: <Icons.DashboardOutlined />,
          label: 'Dashboard',
          route: '/',
          name: 'dashboard',
          children: [],
        } as any,
        ...menuItems,
      ]
    : menuItems;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;
      if (children.length > 0) {
        return (
          <SubMenu key={route} icon={icon ?? <></>} title={label}>
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
              backgroundColor: isSelected ? '#f0f2f5' : 'unset',
              color: isSelected ? '#2a132e' : 'inherit',
            }}
            icon={icon ?? (isRoute && <></>)}
          >
            <Link href={route} to={route} style={{ color: 'inherit' }}>
              <span>{label}</span>
            </Link>
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      className={classNames(
        styles.Sider,
        isMobile ? styles.mobile : styles.normal,
        collapsed ? styles.collapsed : '',
      )}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
    >
      <CustomTitle collapsed={collapsed} />
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
        style={{ flex: 1 }}
      >
        {renderTreeView(menuTree, selectedKey)}
      </Menu>
      <Card
        hoverable
        bordered={false}
        bodyStyle={{ padding: 0 }}
        className={styles.banner}
        cover={<img alt="pro" src="/misc/banner.png" />}
      />
    </AntdLayout.Sider>
  );
};

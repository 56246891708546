import {
  Badge,
  Button,
  Card,
  Col,
  Icons,
  Progress,
  Result,
  Row,
  Space,
  Statistic,
  Typography,
} from '@pankod/refine-antd';

interface CustomCardProps {
  title?: string;
}

interface SuffixBadgeProps {
  value: number;
}

const CustomCard: React.FC<CustomCardProps> = ({ children, title }) => {
  const cardTitle = <Typography.Title level={4}>{title}</Typography.Title>;
  const extra = (
    <Button type="text" shape="circle" icon={<Icons.MoreOutlined />} />
  );

  return (
    <Card title={cardTitle} extra={extra} headStyle={{ borderBottom: 'none' }}>
      {children}
    </Card>
  );
};

const SuffixBadge: React.FC<SuffixBadgeProps> = ({ value }) => {
  const valueAbs = Math.abs(value);
  const valueString = Number.isInteger(value)
    ? valueAbs
    : (valueAbs * 100).toFixed(2) + '%';
  const prefix = value >= 0 ? '+' : '-';
  const type = value >= 0 ? 'success' : 'danger';
  return (
    <Badge
      offset={[4, -12]}
      count={
        <Typography.Text type={type}>
          {prefix} {valueString}
        </Typography.Text>
      }
    />
  );
};

export const Dashboard: React.FC = () => {
  return (
    <Row gutter={12} align="stretch">
      <Col xxl={6} xl={8} lg={10} md={12} sm={24}>
        <Card style={{ height: '100%' }}>
          <Result
            style={{ padding: 24 }}
            icon={<Icons.SmileOutlined />}
            title="Welcome to BOOM Operational System!"
            extra={<Button type="primary">Start Another Great Day</Button>}
          />
        </Card>
      </Col>
      <Col xxl={4} xl={6} lg={8} md={10} sm={24} style={{ display: 'flex' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={12}>
          <CustomCard title="PV">
            <Statistic
              title="Active Users Today"
              value={112893}
              suffix={<SuffixBadge value={0.123678} />}
            />
          </CustomCard>
          <CustomCard title="UV">
            <Statistic
              title="Active Users Today"
              value={8367}
              suffix={<SuffixBadge value={-0.0209} />}
            />
          </CustomCard>
        </Space>
      </Col>
      <Col xxl={4} xl={6} lg={8} md={10} sm={24} style={{ display: 'flex' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={12}>
          <CustomCard title="New Channel">
            <Statistic
              title="Created Today"
              value={11}
              suffix={<SuffixBadge value={2} />}
            />
          </CustomCard>
          <CustomCard title="Active Channel">
            <Statistic
              title="Hot Today"
              value={120}
              suffix={<SuffixBadge value={-16} />}
            />
          </CustomCard>
        </Space>
      </Col>
      <Col xxl={10} xl={12} lg={14} md={16} sm={24} style={{ display: 'flex' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={12}>
          <CustomCard title="Invitation Code Event">
            <Row justify="space-between">
              <Col span={10}>
                <Statistic
                  title="New User joined"
                  value={1801}
                  suffix={<SuffixBadge value={281} />}
                />
              </Col>
              <Col span={10}>
                <Row style={{ marginBottom: 12 }} justify="space-between">
                  <span>Start From:</span>
                  <span>{new Date('2022-05-10').toLocaleDateString()}</span>
                </Row>
                <Progress percent={50} size="small" status="active" />
              </Col>
            </Row>
          </CustomCard>
          <CustomCard title="Airdrop Event">
            <Row justify="space-between">
              <Col span={10}>
                <Statistic
                  title="New User joined"
                  value={1510}
                  suffix={<SuffixBadge value={166} />}
                />
              </Col>
              <Col span={10}>
                <Row style={{ marginBottom: 12 }} justify="space-between">
                  <span>End At:</span>
                  <span>{new Date('2022-06-15').toLocaleDateString()}</span>
                </Row>
                <Progress percent={100} size="small" />
              </Col>
            </Row>
          </CustomCard>
        </Space>
      </Col>
    </Row>
  );
};

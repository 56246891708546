import {
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider,
  HttpError,
} from '@pankod/refine-core';
import axios, { AxiosInstance } from 'axios';
import { API_URL, LS_KEY_AUTH } from 'commons/constants';
import { stringify } from 'query-string';
import { authProvider } from './authProvider';
import { pagePrivider } from './pageProvider';

const axiosInstance = axios.create();

// const resourceNameMap: { [resource: string]: string } = {};

axiosInstance.interceptors.request.use((request) => {
  const { token_type, access_token } = JSON.parse(
    localStorage.getItem(LS_KEY_AUTH) ?? '{}',
  );
  request.headers = {
    ...(request.headers ?? {}),
    Authorization: `${token_type} ${access_token}`,
  };
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;
    const { code, msg } = data ?? {};
    if (!!code) {
      return Promise.reject({
        message: msg,
        statusCode: code,
      });
    }
    return data;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.msg,
      statusCode: error.response?.status,
    };
    if (customError.statusCode === 401) {
      authProvider.logout({});
      window.location.reload();
    }
    return Promise.reject(customError);
  },
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':
    case 'lte':
      return `_${operator}`;
    case 'contains':
      return '_like';
    case 'eq':
    default:
      return '';
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    sort.forEach((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return {
      sort: _sort.join(','),
      order: _order.join(','),
    };
  }

  return {};
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};
  if (filters) {
    filters.forEach((filter) => {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter;

        if (field === 'q') {
          queryFilters[field] = value;
          return;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    });
  }

  return queryFilters;
};

export const providerFactory = (
  apiUrl: string = API_URL,
  httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort, metaData }) => {
    const url = `${apiUrl}/${resource}`;

    const queryFilters = generateFilter(filters);
    const querySort = generateSort(sort);

    // pagination
    const page_size = pagination?.pageSize || 10;
    const page_target = stringify({
      resource,
      page_size,
      ...queryFilters,
      ...querySort,
    });
    const page_token = pagePrivider.get(pagination?.current || 1, page_target);
    const queryPage = {
      page_token,
      page_size,
    };

    const params = {
      ...queryPage,
      ...queryFilters,
      ...querySort,
    };

    const { data } = await httpClient.get(`${url}?${stringify(params)}`);
    const { next_page_token, [metaData?.resourceName || resource]: list } =
      data ?? {};
    const total = pagePrivider.set(
      next_page_token,
      list?.length || 0,
      page_target,
    );

    return {
      data: list,
      total,
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    if (data) {
      pagePrivider.reset();
    }

    return {
      data,
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
        return data;
      }),
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables,
        );
        return data;
      }),
    );

    return { data: response };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url, { ...variables });

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${resource}/${id}`,
          { ...variables },
        );
        return data;
      }),
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const sortQuery = generateSort(sort);
      requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

// 默认全局单例
export const dataProvider = providerFactory();

import { AntdLayout, BackTop, Breadcrumb } from '@pankod/refine-antd';
import classNames from 'classnames';
import { CustomFooter } from './footer';
import { CustomHeader } from './header';
import { CustomSider } from './sider';
import styles from './style.module.css';

export const CustomLayout: React.FC = ({ children }) => (
  <AntdLayout className={classNames(styles.Layout, 'overflow-hidden')}>
    <CustomSider />
    <AntdLayout className="overflow-hidden">
      <CustomHeader />
      <AntdLayout className={styles.contentLayout}>
        <div className={styles.breadcrumbRow}>
          <Breadcrumb hideIcons showHome={false} />
        </div>
        <AntdLayout.Content style={{ minHeight: 'auto' }}>
          <div style={{ margin: '12px 24px', minHeight: 360 }}>{children}</div>
        </AntdLayout.Content>
        <CustomFooter />
      </AntdLayout>
    </AntdLayout>
    <BackTop style={{ right: '1%' }} />
  </AntdLayout>
);

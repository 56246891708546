import { AuthProvider } from '@pankod/refine-core';
import { LS_KEY_AUTH } from 'commons/constants';
import { IUserLogin } from 'commons/interfaces';
import crypto from 'crypto-js';
import { dataProvider } from './dataProvider';

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    if (username && password) {
      try {
        const pwdHash = crypto.MD5(password).toString();
        const { data } = await dataProvider.create<IUserLogin>({
          resource: 'login',
          variables: {
            name: username,
            password: pwdHash,
          },
        });
        localStorage.setItem(LS_KEY_AUTH, JSON.stringify(data));
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(new Error('Please enter username and passwords'));
  },
  logout: () => {
    localStorage.removeItem(LS_KEY_AUTH);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const auth = localStorage.getItem(LS_KEY_AUTH);
    if (auth) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const auth = localStorage.getItem(LS_KEY_AUTH);
    if (!auth) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};

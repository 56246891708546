export const IS_TEST_MODE = process.env.REACT_APP_REFINE_MODE === 'test';

export const LS_KEY_AUTH = 'auth';

export const LS_KEY_PAGE = 'page';

export const API_URL = `https://${
  IS_TEST_MODE ? 'test-' : ''
}admin-api.boomapp.org/v1`;

export const APP_PC_URL = `https://${
  IS_TEST_MODE ? 'test-portal' : 'web'
}.boomapp.org`;

import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  ImageField,
  List,
  Space,
  Table,
  TextField,
  useModal,
  useModalForm,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useTable } from '@pankod/refine-core';
import classNames from 'classnames';
import { IBanner } from 'commons/interfaces';
import { BannerModal } from 'components/modals/banner';
import { BannerSortModal } from 'components/modals/bannerSort';
import dayjs from 'dayjs';
import styles from './style.module.css';

export const BannerList: React.FC<IResourceComponentsProps> = () => {
  const { tableQueryResult, setCurrent } = useTable<IBanner>();

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    form: createForm,
    show: createModalShow,
    onFinish: createFinish,
  } = useModalForm<IBanner>({
    action: 'create',
    onMutationSuccess: () => {
      createForm.resetFields();
    },
  });

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    onFinish: editFinish,
  } = useModalForm<IBanner>({
    action: 'edit',
    warnWhenUnsavedChanges: true,
  });

  const {
    modalProps: sortModalProps,
    show: sortModalShow,
    close: sortModalClose,
  } = useModal();

  const handleSortSaved = () => {
    sortModalClose();
    setCurrent((index) => 1 - index);
  };

  return (
    <List title="" pageHeaderProps={{ breadcrumb: <></> }}>
      <div className="flex-between" style={{ marginBottom: 8 }}>
        <span>&nbsp;</span>
        <Space style={{ marginBottom: 12 }}>
          <Button onClick={() => sortModalShow()}>Sort</Button>
          <Button type="primary" onClick={() => createModalShow()}>
            Create
          </Button>
        </Space>
      </div>
      <Table
        dataSource={tableQueryResult.data?.data}
        loading={
          tableQueryResult.isLoading ||
          tableQueryResult.isFetching ||
          tableQueryResult.isRefetching
        }
        rowKey="id"
        pagination={false}
      >
        <Table.Column
          key="order"
          title="Order"
          width={90}
          render={(_, __, index) => <TextField value={index + 1} />}
        />
        <Table.Column<IBanner>
          dataIndex="image"
          key="banner"
          title="Banner"
          className="text-center"
          align="center"
          render={(value, record) =>
            value ? (
              <ImageField
                value={value}
                title={record.image}
                // width={200}
                height={80}
              />
            ) : (
              <div style={{ height: 80 }}></div>
            )
          }
        />
        <Table.Column
          dataIndex="link"
          key="link"
          title="Link"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.link)}
        />
        <Table.Column<IBanner>
          key="time_range"
          title="Time Range (UTC)"
          render={(_, record) => (
            <span>
              <DateField
                value={dayjs.unix(record.start_time).utc()}
                format="YYYY.MM.DD HH:mm:ss"
              />
              <span style={{ margin: '0 8px' }}>-</span>
              <DateField
                value={dayjs.unix(record.end_time).utc()}
                format="YYYY.MM.DD HH:mm:ss"
              />
            </span>
          )}
        />
        <Table.Column<IBanner>
          title="Action"
          dataIndex="action"
          width={90}
          className="text-center"
          align="center"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => editModalShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                confirmCancelText="No"
              />
            </Space>
          )}
        />
      </Table>
      <BannerModal
        modalProps={createModalProps}
        formProps={createFormProps}
        onFinish={createFinish}
      />
      <BannerModal
        modalProps={editModalProps}
        formProps={editFormProps}
        onFinish={editFinish}
      />
      <BannerSortModal onSaved={handleSortSaved} modalProps={sortModalProps} />
    </List>
  );
};

export const NOTIFICATION_EVENT_TYPE = {
  UNSPECIFIC: 0,
  LIKE: 1,
  COMMENT: 2,
  FOLLOW: 3,
  FREE_CHANNEL: 4,
  PAID_CHANNEL: 5,
  OFFICIAL: 6,
};

export const NOTIFICATION_TARGET_TYPE = {
  UNSPECIFIC: 0,
  ALL: 1,
  ACCOUNT: 2,
};

export const NOTIFICATION_TAB_TYPE = {
  UNSPECIFIC: 0,
  ACTIVITY: 1,
  SUBSCRIPTION: 2,
  OFFICIAL: 3,
};

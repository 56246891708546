import { Icons, Upload } from '@pankod/refine-antd';
import { useApiUrl, useCustom } from '@pankod/refine-core';
import { useEffect, useState } from 'react';
import styles from './style.module.css';

// value and onChange is strict props for Form.Items
// check docs here: https://ant.design/components/form/#components-form-demo-customized-form-controls
export interface Props {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export const SingleDraggerUploader: React.FC<Props> = ({
  disabled,
  value,
  onChange,
}) => {
  const apiUrl = useApiUrl();
  const [imageUrl, setImageUrl] = useState('');
  const [imageBase64, setImageBase64] = useState<string | ArrayBuffer>('');
  const [imageData, setImageData] = useState<FormData>();
  const {
    data: imageResp,
    isLoading: isUploading,
    isRefetching: isReUploading,
  } = useCustom({
    url: `${apiUrl}/upload`,
    method: 'post',
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      payload: imageData,
    },
    queryOptions: {
      enabled: !!imageData,
    },
  });

  const customRequest = ({ file }: any) => {
    const formData = new FormData();
    const reader = new FileReader();
    reader.onload = (r) => setImageBase64(r.target?.result || '');
    reader.readAsDataURL(file);
    formData.append('image', file);
    setImageData(formData);
  };

  useEffect(() => {
    const url = imageResp?.data.url;
    if (!url) return;
    setImageUrl(url);
    setImageData(undefined);
    onChange?.(url);
  }, [imageResp]);

  useEffect(() => {
    setImageUrl(value || '');
  }, [value]);

  return (
    <Upload.Dragger
      accept=".jpg,.jpeg,.png,.gif"
      disabled={isUploading || isReUploading || disabled}
      maxCount={1}
      showUploadList={false}
      customRequest={customRequest}
    >
      {imageUrl ? (
        <div
          className={styles.DraggerCover}
          style={{
            backgroundImage: `url(${imageBase64 || imageUrl})`,
          }}
        />
      ) : (
        <div>
          {isUploading || isReUploading ? (
            <Icons.LoadingOutlined />
          ) : (
            <Icons.PlusOutlined />
          )}
          <div style={{ marginTop: 8 }}>
            {'Drag & drop a image in this area'}
          </div>
          <div style={{ marginTop: 4, fontSize: 12 }}>
            {'( jpg | jpeg | png | gif )'}
          </div>
        </div>
      )}
    </Upload.Dragger>
  );
};

/**
 * 将start/end型分页转为token型分页
 */

import { LS_KEY_PAGE } from 'commons/constants';

interface Page {
  target: string;
  tokens: string[];
  total: number;
}

const emptyPage: Page = {
  target: '',
  tokens: [''],
  total: 0,
};

const getPage = (): Page => {
  const pageString = localStorage.getItem(LS_KEY_PAGE);
  let page;
  if (!pageString) {
    page = { ...emptyPage };
    setPage(page);
  } else {
    page = JSON.parse(pageString);
  }
  return page;
};

const setPage = (page: Page) => {
  localStorage.setItem(LS_KEY_PAGE, JSON.stringify(page));
};

const resetPage = () => {
  localStorage.setItem(LS_KEY_PAGE, JSON.stringify(emptyPage));
};

const checkAndreset = (target: string) => {
  const page = getPage();
  if (target !== page.target) {
    resetPage();
  }
};

export const pagePrivider = {
  get: (current: number, target: string) => {
    checkAndreset(target);
    const index = current - 1; // paginator default start from 1
    const page = getPage();
    return page.tokens[index];
  },
  set: (token: string, count: number, target: string) => {
    checkAndreset(target);
    const page = getPage();
    if (page.target !== target || !page.tokens.includes(token)) {
      page.target = target;
      if (!page.tokens.includes(token)) {
        page.tokens.push(token);
      }
      page.total += count;
      if (page.tokens.length <= 2) {
        page.total += 1;
      }
      setPage(page);
    }
    return page.total;
  },
  reset: () => resetPage(),
};

resetPage(); // when app reload

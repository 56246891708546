import {
  AntdLayout,
  Avatar,
  Badge,
  Button,
  Dropdown,
  Icons,
  Menu,
  MenuProps,
  Space,
} from '@pankod/refine-antd';
import { useLogout } from '@pankod/refine-core';
import classNames from 'classnames';
import { IS_TEST_MODE, LS_KEY_AUTH } from 'commons/constants';
import { IUserLogin } from 'commons/interfaces/user';
import { useEffect, useState } from 'react';
import styles from './style.module.css';

export const CustomHeader: React.FC = () => {
  const [user, setUser] = useState<IUserLogin>();
  const [avatar, setAvatar] = useState('');
  const [allMsgRead, setAllMsgRead] = useState(false);
  const { mutate: logout } = useLogout();

  const items = [
    {
      key: 'toWebsite',
      label: <span>To Web Site</span>,
      icon: <Icons.ChromeOutlined />,
    },
    {
      key: 'toWebapp',
      label: <span>To Web App</span>,
      icon: <Icons.WindowsOutlined />,
    },
    {
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'logout',
      label: <span>Logout</span>,
      icon: <Icons.LogoutOutlined />,
    },
  ];

  const handleSelect: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'logout':
        logout();
        break;
      case 'toWebsite':
        window.open(
          `https://${IS_TEST_MODE ? 'test.' : ''}boomapp.org`,
          '_blank',
        );
        break;
      case 'toWebapp':
        window.open(
          `https://${IS_TEST_MODE ? 'test-portal' : 'web'}.boomapp.org`,
          '_blank',
        );
        break;
      default:
        break;
    }
  };

  const menuUser = <Menu onClick={handleSelect} items={items} />;
  const menuMsg = <Menu items={[{ key: 'empty', label: '(No Message)' }]} />;

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem(LS_KEY_AUTH) || '{}');
    const nameCodePoints =
      auth.name &&
      new Array(auth.name.length)
        .fill(null)
        .map((_, i) => auth.name.codePointAt(i))
        .reduce((a, b) => a + b, 0);
    const avatar = nameCodePoints && `/avatars/${(nameCodePoints % 8) + 1}.png`;
    setAvatar(avatar);
    setUser(auth ?? {});
  }, []);

  return (
    <AntdLayout.Header className={styles.Header}>
      <div className={classNames(styles.contentRow, 'flex-between')}>
        <div>&nbsp;</div>
        <Space size={12}>
          <Dropdown overlay={menuMsg} placement="bottom" trigger={['click']}>
            <Button
              type="text"
              shape="circle"
              icon={
                <Badge dot={!allMsgRead}>
                  <Icons.BellOutlined />
                </Badge>
              }
              onClick={() => setAllMsgRead(true)}
            />
          </Dropdown>
          <Dropdown
            overlay={menuUser}
            placement="bottomRight"
            trigger={['click']}
          >
            <Space style={{ cursor: 'pointer' }}>
              <Avatar src={avatar} icon={<Icons.UserOutlined />} />
              <span>{user?.name || 'Who'}</span>
            </Space>
          </Dropdown>
        </Space>
      </div>
    </AntdLayout.Header>
  );
};

import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  TextField,
} from '@pankod/refine-antd'
import {
  NOTIFICATION_EVENT_TYPE,
  NOTIFICATION_TARGET_TYPE,
} from 'commons/constants'

interface IModal {
  modalProps: ModalProps
  formProps: FormProps
}

export const NotifactionModal: React.FC<IModal> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} width={600}>
      <Form
        {...formProps}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          title: formProps.initialValues?.title,
          content: formProps.initialValues?.content,
          event_type: NOTIFICATION_EVENT_TYPE.OFFICIAL,
          target_type: NOTIFICATION_TARGET_TYPE.ALL,
        }}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              max: 50,
            },
          ]}
        >
          <Input showCount />
        </Form.Item>
        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
              max: 500,
            },
          ]}
        >
          <Input.TextArea showCount autoSize={{ minRows: 5, maxRows: 10 }} />
        </Form.Item>
        <Form.Item label="SendTime" tooltip="UTC time">
          <TextField value="Right Now" />
        </Form.Item>
        <Form.Item name="event_type" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="target_type" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

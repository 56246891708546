import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  Form,
  Input,
  List,
  Pagination,
  Space,
  Table,
  TextField,
  useForm,
  useModalForm,
} from '@pankod/refine-antd'
import {
  CrudFilter,
  IResourceComponentsProps,
  useTable,
} from '@pankod/refine-core'
import classNames from 'classnames'
import { NOTIFICATION_TAB_TYPE } from 'commons/constants'
import { INotification } from 'commons/interfaces'
import { NotifactionModal } from 'components/modals'
import dayjs from 'dayjs'
import styles from './style.module.css'

export const NotificationList: React.FC<IResourceComponentsProps> = () => {
  const { form: searchForm } = useForm({
    submitOnEnter: true,
  })

  const { tableQueryResult, current, setCurrent, setFilters } =
    useTable<INotification>({
      permanentFilter: [
        {
          field: 'tab',
          value: NOTIFICATION_TAB_TYPE.OFFICIAL,
          operator: 'eq',
        },
      ],
    })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    form: createForm,
    show: createModalShow,
  } = useModalForm<INotification>({
    action: 'create',
    // defaultFormValues: {
    //   event_type: NOTIFICATION_EVENT_TYPE.OFFICIAL,
    //   target_type: NOTIFICATION_TARGET_TYPE.ALL,
    // },
    onMutationSuccess: () => {
      createForm.resetFields()
      setCurrent(1)
    },
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
  } = useModalForm<INotification>({
    action: 'edit',
    warnWhenUnsavedChanges: true,
  })

  const handleReset = () => {
    searchForm.resetFields()
    handleSearch()
  }

  const handleSearch = () => {
    const values = searchForm.getFieldsValue()
    const filters = Object.entries(values).map(
      (entry) =>
      ({
        field: entry[0],
        value: entry[1],
        operator: 'eq',
      } as CrudFilter),
    )
    setCurrent(1)
    setFilters(filters)
  }

  return (
    <List title="" pageHeaderProps={{ breadcrumb: <></> }}>
      <div className="flex-between" style={{ marginBottom: 8 }}>
        <Form
          layout="inline"
          style={{ marginBottom: 12 }}
          form={searchForm}
          onFinish={handleSearch}
        >
          <Form.Item label="Keywords" name="query">
            <Input placeholder="Search in title/content" />
          </Form.Item>
        </Form>
        <Space style={{ marginBottom: 12 }}>
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button type="primary" onClick={() => createModalShow()}>
            Create
          </Button>
        </Space>
      </div>
      <Table
        dataSource={tableQueryResult.data?.data}
        loading={
          tableQueryResult.isLoading ||
          tableQueryResult.isFetching ||
          tableQueryResult.isRefetching
        }
        rowKey="id"
        pagination={false}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.id)}
        />
        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.title)}
        />
        <Table.Column
          dataIndex="content"
          key="content"
          title="Content"
          render={(value) => <TextField value={value} />}
          className={classNames(styles.Col, styles.content)}
        />
        <Table.Column
          dataIndex="create_time"
          key="create_time"
          title="Sent At (UTC)"
          render={(value) => (
            <DateField
              value={dayjs.unix(value).utc()}
              format="YYYY.MM.DD HH:mm"
            />
          )}
          className={classNames(styles.Col, styles.date)}
        />
        <Table.Column
          dataIndex="update_time"
          key="update_time"
          title="Updated At (UTC)"
          render={(value) => (
            <DateField
              value={dayjs.unix(value).utc()}
              format="YYYY.MM.DD HH:mm"
            />
          )}
          className={classNames(styles.Col, styles.date)}
        />
        {/* <Table.Column
          dataIndex="user.display_name"
          key="display_name"
          title="Operator"
          render={(value) => <TextField value={value} />}
        /> */}
        <Table.Column<INotification>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => editModalShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                confirmCancelText="No"
              />
            </Space>
          )}
          className={classNames(styles.Col, styles.action)}
        />
      </Table>
      <Pagination
        simple
        current={current}
        onChange={(page: number) => setCurrent(page)}
        total={tableQueryResult.data?.total || 0}
        style={{ textAlign: 'right', marginTop: 20 }}
      />
      <NotifactionModal
        modalProps={createModalProps}
        formProps={createFormProps}
      />
      <NotifactionModal modalProps={editModalProps} formProps={editFormProps} />
    </List>
  )
}

import {
  ErrorComponent,
  Icons,
  LoginPage,
  notificationProvider,
  ReadyPage,
} from '@pankod/refine-antd'
import '@pankod/refine-antd/dist/styles.min.css'
import { Refine } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-react-router-v6'
import { CustomLayout } from 'components/layouts'
import { BannerList } from 'pages/banners'
import { Blacklist } from 'pages/blacklist'
import { Certusers } from 'pages/certusers'
import { ChannelRecList } from 'pages/channelRecs'
import { Dashboard } from 'pages/dashboard'
import { NotificationList } from 'pages/notifications'
import { authProvider, dataProvider } from 'providers'

const MENU_GROUP_MAP = {
  RESOURCE_POS: 'resources',
  MARKET_TOUCH: 'markets',
  USER_MGMT: 'users'
}

const resources = [
  {
    name: MENU_GROUP_MAP.RESOURCE_POS,
    icon: <Icons.BuildOutlined />,
    // options: { label: '资源位管理' }
  },
  {
    name: 'banners',
    parentName: MENU_GROUP_MAP.RESOURCE_POS,
    list: BannerList,
  },
  {
    name: 'channelRecs',
    parentName: MENU_GROUP_MAP.RESOURCE_POS,
    list: ChannelRecList,
  },
  {
    name: MENU_GROUP_MAP.MARKET_TOUCH,
    icon: <Icons.AimOutlined />,
    // options: { label: '营销触达体系' }
  },
  {
    name: 'notifications',
    parentName: MENU_GROUP_MAP.MARKET_TOUCH,
    // options: { route: "notices", label: 'Offical消息' },
    list: NotificationList,
  },
  {
    name: MENU_GROUP_MAP.USER_MGMT,
    icon: <Icons.UserOutlined />,
    // options: { label: '用户管理体系' }
  },
  {
    name: 'blacklists',
    parentName: MENU_GROUP_MAP.USER_MGMT,
    // options: { route: "blacklists", label: '黑名单管理' },
    list: Blacklist,
  },
  {
    name: 'certifiedUsers',
    parentName: MENU_GROUP_MAP.USER_MGMT,
    // options: { route: "certifiedUsers", label: '认证用户管理' },
    list: Certusers,
  },
]

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={CustomLayout}
      ReadyPage={ReadyPage}
      DashboardPage={Dashboard}
      catchAll={<ErrorComponent />}
      routerProvider={routerProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      LoginPage={LoginPage}
      resources={resources}
    />
  )
}

export default App
